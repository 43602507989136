













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ViewerHtml extends Vue {
  @Prop()
  private data!: ArrayBuffer;

  @Prop()
  private attachmentName!: string;

  get embeddableContent(): string {
    const dc = new TextDecoder();
    return dc.decode(this.data);
  }

  mounted() {
    this.$modal.show(this.modalName);
  }

  hideModal() {
    this.$modal.hide(this.modalName);
  }

  get modalName() {
    return `htmlAttachmentPreview-${this.attachmentName}`;
  }
}
